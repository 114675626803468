import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import cx from 'classnames';
import * as yup from 'yup';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { Text } from 'app/components/forms';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { Loader } from 'app/components/ui/Loader';
import { useTrirdStep } from 'app/components/entities/accounts';
import { error } from 'app/components/snackbars';
import { AUTO_APPROVALS_TYPE } from 'types';

import { StepFooter } from '../StepFooter/StepFooter';

import { ReactComponent as questionIcon } from 'images/icon/FontAwesome5Pro/Light/circle-question.svg';
import { ReactComponent as atSignIcon } from 'images/icon/FontAwesome5Pro/Light/at-sign.svg';

import styles from './styles';

const domainRegexCheck = /^([a-z0-9.-]+\.[a-z]{2,4})$/g;

const useStyles = makeStyles(styles);

export interface Props {
  nextStep: () => void;
}

export const ThirdStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { mutateAsync: finishStep } = useTrirdStep();
  const [isLoading, setIsLoading] = useState(false);

  const DOMAIN_RESTRICTION_OPTIONS = [
    {
      id: AUTO_APPROVALS_TYPE.OFF_ALL_INVITE,
      name: t(translations.billing.autoApprovalsType.offInvites),
    },
    {
      id: AUTO_APPROVALS_TYPE.OFF_ON_PER_INVITE,
      name: t(translations.billing.autoApprovalsType.perInvites),
    },
    {
      id: AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
      name: t(translations.billing.autoApprovalsType.allInvites),
    },
  ];

  const validationSchema = yup.object().shape({
    domainType: yup.string().required(t(translations.validation.required)),
    domain: yup
      .string()
      .nullable()
      .when('domainType', {
        is: type => type === AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
        then: () =>
          yup
            .string()
            .matches(
              domainRegexCheck,
              t(translations.validation.domain).replace('@', ''),
            )
            .required(t(translations.validation.required)),
      }),
  });

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
  );

  const initialValues = useMemo(() => {
    return {
      domain:
        user && company
          ? company?.settings?.domainRestrictType?.domain.replace('@', '') || ''
          : '',
      domainType:
        user && company
          ? company?.settings?.domainRestrictType?.type
          : AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
      companyId: company?.id,
    };
  }, [user, company]);

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep(data);
        setIsLoading(false);
        nextStep();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={isCompanyLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!isCompanyLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.third.title)}
          </Typography>
          <Typography>
            {t(translations.accountHolderOnboarding.steps.third.text1)}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              return;
            }}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnMount
          >
            {({ handleSubmit, isValid, values: data, errors }) => (
              <div className={classes.stepContainer}>
                <form
                  className={classes.formContainer}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.formRaw}>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.third.form
                            .domainRestriction,
                        )}
                        name="domainType"
                        type="text"
                        variant="outlined"
                        select
                        options={DOMAIN_RESTRICTION_OPTIONS}
                        required
                      />
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.third
                            .tooltip.domainRestriction,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={classes.formRaw}>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.third.form
                            .domain,
                        )}
                        name="domain"
                        type="text"
                        variant="outlined"
                        placeholder="example.com"
                        disabled={
                          data.domainType !== AUTO_APPROVALS_TYPE.ON_ALL_INVITES
                        }
                        error={!!errors.domain}
                        startIcon={
                          <SvgIcon
                            component={atSignIcon}
                            viewBox="0 0 15 15"
                            className={cx({
                              [classes.disabledIcon]:
                                data.domainType !==
                                AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
                            })}
                          />
                        }
                      />
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.third
                            .tooltip.domain,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <Typography className={classes.footerText}>
                    {t(translations.accountHolderOnboarding.steps.third.text2)}
                  </Typography>
                </form>
                <StepFooter
                  activeStep={3}
                  isValid={isValid}
                  handleNext={async () => await handleFinishStep(data)}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
