import React, { useState, useCallback, useMemo } from 'react';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { useDebounce } from 'react-use';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import UserAutocomplete from 'app/components/ui/UserAutocomplete';
import { INPUT_SEARCH_DEBOUNCE_DELAY } from 'app/components/forms/constants';
import { useUserFilter } from 'app/components/users/hooks/crud';
import { TYPES } from 'app/components/auth/constants';

import { ReactComponent as searchLightIcon } from 'images/icon/FontAwesome5Pro/Light/search.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  name: string;
  onChange: any;
  required?: boolean;
  disabled?: boolean;
}

export default function UserDropdown({
  name,
  onChange,
  required = false,
  disabled = false,
}: Props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');
  // eslint-disable-next-line prefer-const
  let [page, setPage] = useState(1);
  const usersQuery = RequestQueryBuilder.create({ page: 1, limit: 8 * page });

  if (search) {
    usersQuery.search({
      $and: [
        {
          $or: [
            {
              $and: search.split(' ').map(word => ({
                fullName: {
                  $contL: word,
                },
              })),
            },
            {
              email: {
                $contL: search,
              },
            },
          ],
        },
        { $or: [{ type: TYPES.PAID }, { type: TYPES.TRIAL }] },
      ],
    });
  }

  const { data: usersResponse, isFetching } = useUserFilter({
    builder: usersQuery,
  });

  const handleInputChange = useCallback(search => {
    setInputValue(search);
    setPage(1);
  }, []);

  const handleChangePage = useCallback(nextPage => {
    setPage(nextPage);
  }, []);

  useDebounce(
    () => {
      setSearch(inputValue);
    },
    INPUT_SEARCH_DEBOUNCE_DELAY * 2,
    [inputValue],
  );

  return (
    <UserAutocomplete
      name={name}
      filterOptions={options => options.filter(Boolean)}
      className={classes.userSelector}
      placeholder={'Search by name or email...'}
      disableClearable={false}
      optionsIsLoading={isFetching}
      onInputChange={handleInputChange}
      total={usersResponse?.total as number}
      options={usersResponse?.data}
      onChange={onChange}
      required={required}
      disabled={disabled}
      handelChangePage={handleChangePage}
      page={page}
      startIcon={<SvgIcon component={searchLightIcon} viewBox="0 0 512 512" />}
    />
  );
}
