import {
  MutationOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  QueryKey,
} from 'react-query';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { apiClient } from 'app/config/axios';

import { PaginatedResponse } from 'app/interfaces';

interface TagEntity {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface ChildIndex<R> {
  key?: QueryKey;
  builder?: RequestQueryBuilder;
  config?: QueryOptions<PaginatedResponse<R>>;
}

const endpoint = '/tags';

export const tagStore = data =>
  apiClient.post<FormData, TagEntity>(endpoint, data);
export const tagUpdate = (id, data) =>
  apiClient.patch<FormData, TagEntity>(`${endpoint}/${id}`, data);
export const tagShow = (id: number) =>
  apiClient.get<FormData, TagEntity>(`${endpoint}/${id}`);
export const tagIndex = (queryData: any) =>
  apiClient.get<any, PaginatedResponse<TagEntity>>(endpoint, {
    params: queryData,
  });

export const useTagFilterIndex = ({
  key = ['indexTags'],
  builder,
  config = {},
}: ChildIndex<TagEntity>) => {
  const query = builder?.query() ?? '';
  return useQuery<PaginatedResponse<TagEntity>, any>(
    [key, builder],
    () => apiClient.get(`${endpoint}?${query}`),
    {
      ...config,
      retry: false,
    },
  );
};

export const useTagIndex: <T = PaginatedResponse<TagEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexTags', any]>(
    ['indexTags', queryData],
    () => tagIndex(queryData),
    options,
  );

export const useTagStore = (options?: MutationOptions<TagEntity, FormData>) =>
  useMutation<TagEntity, FormData>(['tagStore'], data => tagStore(data), {
    ...options,
  });

export const useTagUpdate = (
  id: number,
  options?: MutationOptions<TagEntity, FormData>,
) =>
  useMutation<TagEntity, FormData>(['tagUpdate'], data => tagUpdate(id, data), {
    ...options,
  });

export const useTagShow = (
  id: number,
  options?: QueryOptions<TagEntity, any>,
) =>
  useQuery<TagEntity, ['tagShow', number]>(
    ['tagShow', id],
    () => tagShow(id),
    options,
  );
