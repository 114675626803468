import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { PreviewModal } from 'app/components/entities/timeplates';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { SignaturesSearch } from 'app/components/forms/components/Edit/Search/SignaturesSearch';
import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { useConfirmation, useModal } from 'app/components/ui/Modal';
import { COMPANY_TYPES } from 'app/containers/constants';
import { translations } from 'locales/i18n';

import { ReactComponent as editLightIcon } from 'images/icon/FontAwesome5Pro/Light/edit.svg';
import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as clockLightIcon } from 'images/icon/FontAwesome5Pro/Light/clock.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';
import { ReactComponent as modalClockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-clone.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  handleCloneSignature: (id: any) => void;
  handleDeleteSignature: (id: any) => void;
  companyId?: string;
  locationId?: string;
  backLink?: string;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
}

export default function GhostList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  locationId,
  indexQuery,
  handleCloneSignature,
  handleDeleteSignature,
  initialStateAdditionalParams = {},
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [versionId, setVersionId] = useState();
  const [signatureId, setSignatureId] = useState();
  const [showPreview] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: PreviewModal,
    props: { versionId, isTimeplate: false },
  });

  const onShowPreview = id => () => {
    if (id) {
      setVersionId(id);
      showPreview();
    }
  };

  const [showCloneConfirmation] = useConfirmation({
    onSubmit: () => handleCloneSignature(signatureId),
    description: t(translations.ui.titles.confirmationCloneSignatureMessage),
    agreeLabel: t(translations.ui.buttons.clone),
    modalIcon: modalClockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: '',
    },
  });

  const cloneSignature = id => {
    setSignatureId(id);
    showCloneConfirmation();
  };

  const [showGhostDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeleteSignature(signatureId),
    title: t(translations.ui.titles.confirmationGhostSignatureDeleteTitle),
    description: t(translations.ui.titles.confirmationGhostSignatureDelete),
    maxWidth: 'md',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleGhostSignatureDeleteClick = useCallback(
    id => {
      setSignatureId(id);
      showGhostDeleteConfirmation();
    },
    [showGhostDeleteConfirmation],
  );

  const columns = [
    {
      Header: t(translations.table.columns.signatureName),
      accessor: 'name',
      customProps: row =>
        locationId
          ? `/ghost-timeplates/${row.location.companyId}/${row.locationId}/${row.id}`
          : `/ghost-timeplates/${row.location.companyId}/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.companyName),
      id: 'location.company.name',
      accessor: ({
        location: {
          company: { name },
        },
      }) => name,
    },
    {
      Header: t(translations.table.columns.locationName),
      id: 'locationId',
      accessor: ({ location: { name } }) => name,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      customProps: row =>
        locationId
          ? `/ghost-signature-users/${row.location.companyId}/${row.locationId}/${row.id}`
          : `/ghost-signature-users/${row.location.companyId}/${row.id}`,

      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.timeplates),
      id: 'timeplatesCount',
      accessor: 'timeplatesCount',
      customProps: row =>
        locationId
          ? `/ghost-timeplates/${row.location.companyId}/${row.locationId}/${row.id}`
          : `/ghost-timeplates/${row.location.companyId}/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.activeTimeplates),
      id: 'timeplates.name',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      accessor: () => {},
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original.timeplates?.find(({ isActive }) => isActive)?.name || '',
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 300,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({
        id,
        timeplates,
        location: { id: locationId, companyId },
      }) => {
        return { id, timeplates, locationId, companyId };
      },
      Cell: ({
        cell: {
          value: { id, timeplates, locationId, companyId },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              className={classes.actionItem}
              href={`/ghost-signatures/${id}/edit`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/ghost-signatures/${id}/edit-data`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editSignature)}
              >
                <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
              </Tooltip>
            </Link>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.preview)}
              >
                <SvgIcon
                  component={eyeLightIcon}
                  viewBox="0 0 22 17"
                  onClick={onShowPreview(
                    timeplates?.find(({ isActive }) => !!isActive)
                      ?.activeVersionId,
                  )}
                />
              </Tooltip>
            </span>
            <Link
              className={classes.actionItem}
              href={`/ghost-signature-users/${companyId}/${locationId}/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.ghostUsers)}
              >
                <SvgIcon component={userLightIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/ghost-timeplates/${companyId}/${locationId}/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.ghostTimeplates)}
              >
                <SvgIcon component={clockLightIcon} viewBox="0 0 18 19" />
              </Tooltip>
            </Link>
            <span
              className={classes.actionItem}
              onClick={() => cloneSignature(id)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.clone)}
              >
                <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
            <span
              className={classes.actionItem}
              onClick={() => handleGhostSignatureDeleteClick(id)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.delete)}
              >
                <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const initialState = {
    globalFilter: {
      'location.company.type': { $eq: COMPANY_TYPES.GHOST },
      ...(locationId ? { locationId } : { 'location.companyId': companyId }),
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper
      {...(locationId || companyId
        ? {
            header: (
              <Typography variant="h5">
                {t(translations.names.ghost.signatures)}
              </Typography>
            ),
            backLink: locationId ? `/ghost-locations/${companyId}` : '/',
          }
        : {})}
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <SignaturesSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
