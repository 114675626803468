/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useInjectReducer } from 'utils/redux-injectors';
import { GuestRoute } from 'app/components/auth/components/route/GuestRoute';
import { ProtectedRoute } from 'app/components/auth/components/route/ProtectedRoute';
import { PaidWaitingProtectedRoute } from 'app/components/auth/components/route/PaidWaitingProtectedRoute';
import { TrialProtectedRoute } from 'app/components/auth/components/route/TrialProtectedRoute';
import { AccountHolderLayout, OnboardingLayout } from 'app/components/layouts';
import { DashboardLayout } from 'app/components/layouts';
import {
  reducer as authReducer,
  sliceKey as authSliceKey,
} from 'app/components/auth/store/slice';

import { ThemePage } from 'app/containers/ThemePage/Loadable';
import { NotFoundErrorPage } from 'app/containers/Error/NotFound/Loadable';
import { ForbiddenErrorPage } from 'app/containers/Error/Forbidden/Loadable';
import { GeneralErrorPage } from 'app/containers/Error/General/Loadable';
import { LoginPage } from 'app/containers/Authorization/LoginPage/Loadable';
import { ForgotPasswordPage } from 'app/containers/Authorization/ForgotPasswordPage/Loadable';
import { ResetPasswordPage } from 'app/containers/Authorization/ResetPasswordPage/Loadable';
import { WaitingPage } from 'app/containers/Waiting/Loadable';
import { CompaniesPage } from 'app/containers/CompaniesPage/Loadable';
import { CompanyEdit } from 'app/containers/CompaniesPage/Edit/Loadable';
import { LocationsPage } from 'app/containers/LocationsPage/Loadable';
import { LocationEdit } from 'app/containers/LocationsPage/Edit/Loadable';
import { SignaturesPage } from 'app/containers/SignaturesPage/Loadable';
import { SignatureEditProfile } from 'app/containers/SignaturesPage/EditProfile/Loadable';
import { SignatureEdit } from 'app/containers/SignaturesPage/Edit/Loadable';
import { SignatureUsersPage } from 'app/containers/SignatureUsersPage/Loadable';
import { SignatureUserEdit } from 'app/containers/SignatureUsersPage/Edit/Loadable';
import { TimeplateEditProfile } from 'app/containers/TimeplatesPage/EditProfile/Loadable';
import { TimeplatesPage } from 'app/containers/TimeplatesPage/Loadable';
import { TimeplateHtmlEdit } from 'app/containers/TimeplatesPage/EditHtml/Loadable';
import { TimeplateEdit } from 'app/containers/TimeplatesPage/Edit/Loadable';
import { DesignerPage } from 'app/containers/TimeplatesPage/Designer/Loadable';
import { PeoplePage } from 'app/containers/PeoplePage/Loadable';
import { InvitesPage } from 'app/containers/InvitesPage/Loadable';
import { UsersPage } from 'app/containers/UsersPage/Loadable';
import { UserNew } from 'app/containers/UsersPage/New/Loadable';
import { IconsPage } from 'app/containers/Icons/Loadable';
import { IconSetEdit } from 'app/containers/Icons/Edit/Loadable';
import { UserEdit } from 'app/containers/UsersPage/Edit/Loadable';
import { WidgetsPage } from 'app/containers/WidgetsPage/Loadable';
import { WidgetNew } from 'app/containers/WidgetsPage/New/Loadable';
import { WidgetEdit } from 'app/containers/WidgetsPage/Edit/Loadable';
import { WidgetPreviewDirectLink } from 'app/containers/WidgetsPage/PreviewByDirectLink/Loadable';
import { DesignsPage } from 'app/containers/DesignsPage/Loadable';
import { DesignNew } from 'app/containers/DesignsPage/New/Loadable';
import { DesignEdit } from 'app/containers/DesignsPage/Edit/Loadable';
import { OnboardingPage } from 'app/containers/OnboardingPage/Loadable';
import { OnboardingUserVerification } from 'app/containers/OnboardingPage/setUpPassword/Loadable';
import { SharePage } from 'app/containers/OnboardingPage/SharePage/Loadable';
import { InvitePage } from 'app/containers/OnboardingPage/InvitePage/Loadable';
import { TrialUserPage } from 'app/containers/TrialUserPage/Loadable';
import { Base64IconsPage } from 'app/containers/Icons/base64';
import { TestImage } from 'app/containers/TestImage';
import { PAGE_TYPE } from 'app/containers/constants';
import { QueuePage } from 'app/containers/QueuePage/Loadable';
import { InQueuePage } from 'app/containers/QueuePage/InQueuePage/Loadable';
import { AccountsPage } from 'app/containers/AccountsPage';
import { GhostPage } from 'app/containers/GhostPage';
import { GhostUserEdit } from 'app/containers/GhostUsersPage/Edit';
import { GhostSignaturesPage } from 'app/containers/GhostSignaturesPage';
import { TrialPage } from 'app/containers/TrialPage/Loadable';
import { AccountHolderPage } from './containers/AccountHolderPage';
import { AuthWrapper } from './components/auth/components/route/AuthWrapper';
import { UserSignaturesPage } from './containers/UserSignaturesPage/Loadable';

export function App() {
  useInjectReducer({ key: authSliceKey, reducer: authReducer });

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - SigSuite" defaultTitle="SigSuite">
        <meta name="description" content="Welcome to SigSuite" />
      </Helmet>
      <Routes>
        <Route path="/testImage" element={<TestImage />} />
        {['/icon-sets', '/icon-sets/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <IconsPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path="/icon-sets/:id/edit"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <IconSetEdit />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {['/people/:id/edit', '/people/:id/edit/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UserEdit
                    ghostBackLink="/ghost-users"
                    trialBackLink="/trial-users"
                    peopleBackLink="/people"
                    pageType={PAGE_TYPE.PAID}
                  />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/profile/:id/edit', '/profile/:id/edit/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UserEdit
                    ghostBackLink="/ghost-users"
                    trialBackLink="/trial-users"
                    peopleBackLink="/people"
                    pageType={PAGE_TYPE.PAID}
                  />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/accounts/:id/edit', '/accounts/:id/edit/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UserEdit
                    ghostBackLink="/ghost-users"
                    trialBackLink="/trial-users"
                    peopleBackLink="/accounts"
                    pageType={PAGE_TYPE.PAID}
                  />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/trial-users/:id/edit', '/trial-users/:id/edit/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UserEdit
                    ghostBackLink="/ghost-users"
                    trialBackLink="/trial-users"
                    peopleBackLink="/people"
                    pageType={PAGE_TYPE.TRIALS}
                  />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/ghost-users/:id/edit', '/ghost-users/:id/edit/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GhostUserEdit
                    ghostBackLink="/ghost/people"
                    trialBackLink="/trial-users"
                    peopleBackLink="/people"
                    pageType={PAGE_TYPE.GHOSTS}
                  />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/people/:companyId', '/people/:companyId/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <PeoplePage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path="/accounts"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <AccountsPage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/queue/:companyId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <QueuePage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/queue/:companyId/:tab/:queueId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <InQueuePage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {['/invites/:companyId', '/invites/:companyId/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <InvitesPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/trials', '/trials/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TrialPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/ghost', '/ghost/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GhostPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path={'/companies/:accountId'}
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <CompaniesPage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {[
          '/timeplates/:id/edit',
          '/timeplates/:id/edit/:tab',
          '/timeplates/:id/edit/:tab/:subtab',
          '/ghost-timeplates/:id/edit',
          '/ghost-timeplates/:id/edit/:tab',
          '/ghost-timeplates/:id/edit/:tab/:subtab',
          '/trial-timeplates/:id/edit',
          '/trial-timeplates/:id/edit/:tab',
          '/trial-timeplates/:id/edit/:tab/:subtab',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TimeplateEditProfile />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/timeplates/:id/edit-html',
          '/ghost-timeplates/:id/edit-html',
          '/trial-timeplates/:id/edit-html',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <TimeplateHtmlEdit />
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/timeplates/:id/edit-data',
          '/ghost-timeplates/:id/edit-data',
          '/trial-timeplates/:id/edit-data',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TimeplateEdit />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path="/timeplates/:id/designer"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignerPage pageType={PAGE_TYPE.PAID} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ghost-timeplates/:id/designer"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignerPage pageType={PAGE_TYPE.GHOSTS} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/trial-timeplates/:id/designer"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignerPage pageType={PAGE_TYPE.TRIALS} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/locations/:companyId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <LocationsPage pageType={PAGE_TYPE.PAID} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ghost-locations/:companyId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <LocationsPage pageType={PAGE_TYPE.GHOSTS} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/trial-locations/:companyId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <LocationsPage pageType={PAGE_TYPE.TRIALS} />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {[
          '/signatures/:id/edit',
          '/signatures/:id/edit/:tab',
          '/signatures/:id/edit/:tab/:subtab',
          '/ghost-signatures/:id/edit',
          '/ghost-signatures/:id/edit/:tab',
          '/ghost-signatures/:id/edit/:tab/:subtab',
          '/trial-signatures/:id/edit',
          '/trial-signatures/:id/edit/:tab',
          '/trial-signatures/:id/edit/:tab/:subtab',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureEditProfile />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/signatures/:companyId', '/signatures/:companyId/:locationId'].map(
          path => (
            <Route
              path={path}
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <SignaturesPage pageType={PAGE_TYPE.PAID} />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />
          ),
        )}
        {['/user-signatures', '/user-signatures/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UserSignaturesPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/ghost-signatures/:companyId',
          '/ghost-signatures/:companyId/:locationId',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GhostSignaturesPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/trial-signatures/:companyId',
          '/trial-signatures/:companyId/:locationId',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignaturesPage pageType={PAGE_TYPE.TRIALS} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/signature-users/:id/edit',
          '/signature-users/:id/edit/:tab',
          '/signature-users/:id/edit/:tab/:subtab',
          '/trial-signature-users/:id/edit',
          '/trial-signature-users/:id/edit/:tab',
          '/trial-signature-users/:id/edit/:tab/:subtab',
          '/ghost-signature-users/:id/edit',
          '/ghost-signature-users/:id/edit/:tab',
          '/ghost-signature-users/:id/edit/:tab/:subtab',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureUserEdit />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/signature-users/:companyId',
          '/signature-users/:companyId/:signatureId',
          '/signature-users/:companyId/:locationId',
          '/signature-users/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureUsersPage pageType={PAGE_TYPE.PAID} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/ghost-signature-users/:companyId',
          '/ghost-signature-users/:companyId/:signatureId',
          '/ghost-signature-users/:companyId/:locationId',
          '/ghost-signature-users/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureUsersPage pageType={PAGE_TYPE.GHOSTS} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/trial-signature-users/:companyId',
          '/trial-signature-users/:companyId/:signatureId',
          '/trial-signature-users/:companyId/:locationId',
          '/trial-signature-users/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureUsersPage pageType={PAGE_TYPE.TRIALS} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/timeplates/:companyId',
          '/timeplates/:companyId/:signatureId',
          '/timeplates/:companyId/:locationId',
          '/timeplates/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TimeplatesPage pageType={PAGE_TYPE.PAID} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/ghost-timeplates/:companyId',
          '/ghost-timeplates/:companyId/:signatureId',
          '/ghost-timeplates/:companyId/:locationId',
          '/ghost-timeplates/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TimeplatesPage pageType={PAGE_TYPE.GHOSTS} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/trial-timeplates/:companyId',
          '/trial-timeplates/:companyId/:signatureId',
          '/trial-timeplates/:companyId/:locationId',
          '/trial-timeplates/:companyId/:locationId/:signatureId',
        ].map(path => (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TimeplatesPage pageType={PAGE_TYPE.TRIALS} />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/companies/:id/edit',
          '/companies/:id/edit/:tab',
          '/companies/:id/edit/:tab/:subtab',
          '/ghost-companies/:id/edit',
          '/ghost-companies/:id/edit/:tab',
          '/ghost-companies/:id/edit/:tab/:subtab',
          '/trials/:id/edit',
          '/trials/:id/edit/:tab',
          '/trials/:id/edit/:tab/:subtab',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CompanyEdit />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {[
          '/locations/:id/edit',
          '/locations/:id/edit/:tab',
          '/locations/:id/edit/:tab/:subtab',
          '/ghost-locations/:id/edit',
          '/ghost-locations/:id/edit/:tab',
          '/ghost-locations/:id/edit/:tab/:subtab',
          '/trial-locations/:id/edit',
          '/trial-locations/:id/edit/:tab',
          '/trial-locations/:id/edit/:tab/:subtab',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <LocationEdit />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}

        {[
          '/trial-signatures/:id/edit-data',
          '/ghost-signatures/:id/edit-data',
        ].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SignatureEdit />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        {['/widgets/tabs', '/widgets/tabs/:tab'].map(path => (
          <Route
            path={path}
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <WidgetsPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path="/widgets/new"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <WidgetNew />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/widgets/:id/edit"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <WidgetEdit />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/widgets/:hash" element={<WidgetPreviewDirectLink />} />
        <Route path="/share/:token" element={<SharePage />} />
        <Route path="/invite/:token" element={<InvitePage />} />
        <Route
          path="/designs/:widgetId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignsPage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/designs/:widgetId/new"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignNew />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/designs/:designId/edit"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <DesignEdit />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {/* trial users routes */}
        <Route
          path="/trial-users/:companyId"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <UsersPage />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/trial-users/:companyId/new"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <UserNew />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/trial/:userId"
          element={
            <TrialProtectedRoute>
              <OnboardingLayout>
                <TrialUserPage />
              </OnboardingLayout>
            </TrialProtectedRoute>
          }
        />
        <Route
          path="/account-holder/onboarding"
          element={
            <ProtectedRoute>
              <AccountHolderLayout>
                <AccountHolderPage />
              </AccountHolderLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/theme" element={<ThemePage />} />
        <Route
          path="/base64icons/:id/:baseColor/:featureColor"
          element={<Base64IconsPage />}
        />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <LoginPage />
            </GuestRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPasswordPage />
            </GuestRoute>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <GuestRoute>
              <ResetPasswordPage />
            </GuestRoute>
          }
        />
        <Route
          path="/signature/:hash"
          element={
            <GuestRoute>
              <OnboardingPage />
            </GuestRoute>
          }
        />
        <Route
          path="/onboarding/emailVerification/:token"
          element={
            <GuestRoute>
              <OnboardingUserVerification />
            </GuestRoute>
          }
        />
        <Route
          path="/paid-waiting"
          element={
            <PaidWaitingProtectedRoute>
              <DashboardLayout>
                <WaitingPage />
              </DashboardLayout>
            </PaidWaitingProtectedRoute>
          }
        />
        <Route path="/" element={<AuthWrapper />} />
        <Route path="/something-went-wrong" element={<GeneralErrorPage />} />
        <Route path="/access-denied" element={<ForbiddenErrorPage />} />
        <Route path="/not-found" element={<NotFoundErrorPage />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
