import React, { memo } from 'react';
import cx from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { RequestQueryBuilder } from '@dataui/crud-request';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { translations } from 'locales/i18n';
import useAcl from 'app/components/auth/hooks/useAcl';
import { Logo } from 'app/components/layouts/components/Logo';
import { ROLES } from 'app/components/auth';
import { MenuItem } from 'app/components/layouts/types';
import { ROLES_TITLE } from 'app/components/auth/constants';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { sideBarActionConfig } from 'app/components/layouts/constants';
import { Loader } from 'app/components/ui/Loader';
import { useQueueFilterIndex } from 'app/components/queue';

import AccordionBlock from './AccordionBlock';

import { ReactComponent as stopwatchLightIcon } from 'images/icon/FontAwesome5Pro/Light/stopwatch.svg';
import { ReactComponent as ghostLightIcon } from 'images/icon/FontAwesome5Pro/Light/ghost.svg';
import { ReactComponent as iconsIcon } from 'images/icon/FontAwesome5Pro/Light/icons.svg';
import { ReactComponent as thLightIcon } from 'images/icon/FontAwesome5Pro/Light/th.svg';
import { ReactComponent as crownLightIcon } from 'images/icon/FontAwesome5Pro/Light/crown.svg';

import styles from '../styles';
const useStyles = makeStyles(styles);

interface SidebarProps {
  drawerMinimize: boolean;
  mobileOpen: boolean;
  handleDrawerMobileToggle: any;
  handleDrawerMinimizeToggle: any;
}

const superAdminMenu: MenuItem[] = [
  {
    name: 'Accounts',
    to: '/accounts',
    icon: <SvgIcon component={crownLightIcon} viewBox="0 0 640 512" />,
    iconSize: 20,
    activeRoutes: [
      '/accounts',
      '/companies',
      '/people',
      '/invites',
      '/location',
      '/queue',
      '/signatures',
      '/signature-users',
      '/timeplates',
    ],
    allowedForRoles: [ROLES.SUPER_ADMIN],
  },
  {
    name: 'Trials',
    to: '/trials',
    icon: <SvgIcon component={stopwatchLightIcon} viewBox="0 0 448 512" />,
    iconSize: 20,
    activeRoutes: ['/trials', '/trial'],
    allowedForRoles: [ROLES.SUPER_ADMIN],
  },
  {
    name: 'Ghosts',
    to: '/ghost/companies',
    icon: <SvgIcon component={ghostLightIcon} viewBox="0 0 384 512" />,
    iconSize: 20,
    activeRoutes: ['/ghost', '/users'],
    allowedForRoles: [ROLES.SUPER_ADMIN],
  },
  {
    name: 'Icon Sets',
    to: '/icon-sets/active',
    icon: <SvgIcon component={iconsIcon} viewBox="0 0 512 512" />,
    iconSize: 20,
    activeRoutes: ['/icon'],
    allowedForRoles: [ROLES.SUPER_ADMIN],
  },
  {
    name: 'Widgets',
    to: '/widgets/tabs/active',
    icon: <SvgIcon component={thLightIcon} viewBox="0 0 512 512" />,
    iconSize: 20,
    activeRoutes: ['/widgets', '/design'],
    allowedForRoles: [ROLES.SUPER_ADMIN],
  },
];

const Sidebar: React.FC<SidebarProps> = ({
  drawerMinimize,
  mobileOpen,
  handleDrawerMobileToggle,
  handleDrawerMinimizeToggle,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isOneOf, isAdmin, user } = useAcl();
  const location = useLocation();
  const classes = useStyles();
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const startsWith = (urls: string[]) =>
    urls.some((url: string) => location.pathname.startsWith(url));

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
    {
      enabled: isOneOf([
        ...sideBarActionConfig.companyAdmin,
        ROLES.BILLING_ADMIN,
      ]),
    },
  );

  const { data: queue } = useQueueFilterIndex(
    {
      limit: 1,
      page: 1,
      ...RequestQueryBuilder.create({
        search: {
          type: 'main',
          companyId: company?.id,
        },
      }).queryObject,
    },
    {
      enabled:
        !!company?.id &&
        !company?.settings?.autoPay &&
        isOneOf([
          ROLES.ACCOUNT_HOLDER,
          ROLES.ACCOUNT_HOLDER_PRIMARY,
          ROLES.COMPANY_ADMIN,
          ROLES.COMPANY_ADMIN_PRIMARY,
          ROLES.BILLING_ADMIN,
        ]),
      refetchInterval: 300000,
    },
  );
  const totalUserAmount =
    (queue &&
      Number(queue?.data[0]?.processed_users) +
        Number(queue?.data[0]?.queued_users)) ||
    0;

  return (
    <>
      <Loader loading={isCompanyLoading} />
      <Drawer
        anchor="left"
        classes={{
          root: cx(classes.sidebar, {
            [classes.sidebarMinimize]: drawerMinimize,
          }),
          paper: classes.sidebarPaper,
        }}
        variant={respUpMd ? 'permanent' : 'temporary'}
        open={mobileOpen}
        onClose={handleDrawerMobileToggle}
        elevation={0}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.sidebarHeader}>
          <Toolbar className={classes.sidebarHeaderToolbar} disableGutters>
            <Logo />
          </Toolbar>
        </div>
        <div className={classes.sidebarTitle}>{`${
          ROLES_TITLE[user?.mainRole]
        } MENU`}</div>
        <div className={classes.sidebarContent}>
          {isAdmin ? (
            <List component="nav">
              {superAdminMenu
                .filter(
                  ({ allowedForRoles }) =>
                    !allowedForRoles || isOneOf(allowedForRoles),
                )
                .map(
                  ({
                    name,
                    to,
                    icon,
                    iconSize,
                    show = true,
                    exact = false,
                    disabled = false,
                    activeRoutes = [],
                    counter,
                  }) =>
                    show && (
                      <ListItem
                        className={cx(
                          classes.sidebarNavItem,
                          startsWith(activeRoutes) ? 'active' : '',
                        )}
                        button
                        key={name}
                        component={NavLink as any}
                        to={to}
                        onClick={handleDrawerMobileToggle}
                        exact={exact.toString()}
                        disabled={disabled}
                        selected={false}
                      >
                        <ListItemIcon
                          className={classes.sidebarNavIcon}
                          style={{ fontSize: iconSize ? iconSize : 22 }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.sidebarNavTextWrapper,
                            primary: classes.sidebarNavText,
                          }}
                          primary={name}
                        />
                        {counter && (
                          <div className={classes.sidebarNavCounterBlock}>
                            <div className={classes.sidebarNavCounter}>
                              {counter}
                            </div>
                          </div>
                        )}
                      </ListItem>
                    ),
                )}
            </List>
          ) : (
            <div className={classes.sidebarNav}>
              {user?.companies?.map(({ name, id }) => {
                return (
                  <AccordionBlock
                    key={id}
                    name={name}
                    companyId={id}
                    drawerMinimize={drawerMinimize}
                    handleDrawerMinimizeToggle={handleDrawerMinimizeToggle}
                  />
                );
              })}
            </div>
          )}
          {isOneOf([
            ROLES.ACCOUNT_HOLDER,
            ROLES.ACCOUNT_HOLDER_PRIMARY,
            ROLES.COMPANY_ADMIN,
            ROLES.COMPANY_ADMIN_PRIMARY,
            ROLES.BILLING_ADMIN,
          ]) &&
          !company?.settings?.autoPay &&
          !!totalUserAmount ? (
            <div
              className={cx(classes.waitingListBlock, {
                [classes.isMinimized]: drawerMinimize,
              })}
            >
              <Typography
                className={classes.waitingCompanyName}
              >{`${company?.name} Waiting List`}</Typography>

              <Typography variant={'caption'} className={classes.waitingText}>
                <Trans
                  i18nKey="sidebar.waitingListMessage"
                  values={{
                    userNumber: totalUserAmount,
                  }}
                />
              </Typography>
              <div className={classes.waitingButtonsBlock}>
                {isOneOf(sideBarActionConfig.companyAdmin) ? (
                  <Button
                    onClick={() =>
                      navigate(`/companies/${company?.id}/edit/settings`)
                    }
                    className={classes.waitingButton}
                  >
                    {t(translations.sidebar.settings)}
                  </Button>
                ) : null}
                <Button
                  onClick={() =>
                    navigate(`/companies/${company?.id}/edit/billing`)
                  }
                  className={cx(classes.waitingButton, 'planButton')}
                >
                  {t(translations.sidebar.plan)}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.sidebarFooter}>Powered by SigSuite</div>
      </Drawer>
    </>
  );
};

export default memo(Sidebar);
