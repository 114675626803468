import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { PAGE_TYPE } from 'app/containers/constants';
import { translations } from 'locales/i18n';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { LocationsSearch } from 'app/components/forms/components/Edit/Search/LocationsSearch';
import { useConfirmation } from 'app/components/ui/Modal';

import { ReactComponent as addressCardLightIcon } from 'images/icon/FontAwesome5Pro/Light/address-card.svg';
import { ReactComponent as modalClockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-clone.svg';
import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  handleCloneLocation: (id: any) => void;
  handleDeleteLocation: (id: any) => void;
  companyId: string;
  pageType: PAGE_TYPE;
  backLink?: string;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  pageType,
  backLink,
  indexQuery,
  handleCloneLocation,
  handleDeleteLocation,
  initialStateAdditionalParams = {},
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [locationId, setLocationId] = useState();

  const urlEntityType = useCallback(
    params => {
      switch (pageType) {
        case PAGE_TYPE.GHOSTS:
          return `/ghost-${params}`;
        case PAGE_TYPE.PAID:
          return `/${params}`;
        default:
          return `/trial-${params}`;
      }
    },
    [pageType],
  );

  const signatureTooltip = {
    [PAGE_TYPE.GHOSTS]: t(translations.actions.names.ghostSignatures),
    [PAGE_TYPE.PAID]: t(translations.actions.names.signatures),
    [PAGE_TYPE.TRIALS]: t(translations.actions.names.trialSignatures),
  };

  const signatureUserTooltip = {
    [PAGE_TYPE.GHOSTS]: t(translations.actions.names.ghostUsers),
    [PAGE_TYPE.PAID]: t(translations.actions.names.users),
    [PAGE_TYPE.TRIALS]: t(translations.actions.names.trialUsers),
  };

  const [showCloneConfirmation] = useConfirmation({
    onSubmit: () => handleCloneLocation(locationId),
    description: t(translations.ui.titles.confirmationCloneLocationMessage),
    agreeLabel: t(translations.ui.buttons.clone),
    modalIcon: modalClockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: '',
    },
  });

  const cloneLocation = id => {
    setLocationId(id);
    showCloneConfirmation();
  };

  const [showGhostDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeleteLocation(locationId),
    title: t(translations.ui.titles.confirmationGhostLocationDeleteTitle),
    description: t(translations.ui.titles.confirmationGhostLocationDelete),
    maxWidth: 'md',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleGhostLocationDeleteClick = useCallback(
    id => {
      setLocationId(id);
      showGhostDeleteConfirmation();
    },
    [showGhostDeleteConfirmation],
  );

  const columns = [
    {
      Header: t(translations.table.columns.locationName),
      accessor: 'name',
      customProps: row =>
        urlEntityType(`signatures/${row.companyId}/${row.id}`),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.signatures),
      id: 'signaturesCount',
      accessor: 'signaturesCount',
      customProps: row =>
        urlEntityType(`signatures/${row.companyId}/${row.id}`),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      customProps: row =>
        urlEntityType(`signature-users/${row.companyId}/${row.id}`),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      className: 'table-action-cell',
      width: 250,
      accessor: ({ id }) => {
        return { id };
      },
      Cell: ({
        cell: {
          value: { id },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div>
            <Link
              className={classes.actionItem}
              href={urlEntityType(`locations/${id}/edit`)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={urlEntityType(`signatures/${companyId}/${id}`)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={signatureTooltip[pageType]}
              >
                <SvgIcon
                  component={addressCardLightIcon}
                  viewBox="0 0 576 512"
                />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={urlEntityType(`signature-users/${companyId}/${id}`)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={signatureUserTooltip[pageType]}
              >
                <SvgIcon component={userLightIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            {pageType === PAGE_TYPE.GHOSTS ? (
              <span
                className={classes.actionItem}
                onClick={() => cloneLocation(id)}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.clone)}
                >
                  <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
            {pageType === PAGE_TYPE.GHOSTS ? (
              <span
                className={classes.actionItem}
                onClick={() => handleGhostLocationDeleteClick(id)}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.delete)}
                >
                  <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
          </div>
        );
      },
    },
  ];

  const pageName = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.GHOSTS:
        return t(translations.names.ghost.locations);
      case PAGE_TYPE.PAID:
        return t(translations.names.paid.locations);
      default:
        return t(translations.names.trials.locations);
    }
  }, [pageType, t]);

  const initialState = {
    globalFilter: {
      companyId,
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper
      header={<Typography variant="h5">{pageName()}</Typography>}
      backLink={backLink}
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <LocationsSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
