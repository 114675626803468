import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { PreviewModal } from 'app/components/entities/timeplates';
import { SignaturesSearch } from 'app/components/forms/components/Edit/Search/SignaturesSearch';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { useModal } from 'app/components/ui/Modal';
import { translations } from 'locales/i18n';
import { PAGE_TYPE } from 'app/containers/constants';

import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as clockLightIcon } from 'images/icon/FontAwesome5Pro/Light/clock.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as addCollectionIcon } from 'images/icon/FontAwesome5Pro/Light/addCollection.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId: string;
  locationId?: string;
  pageType: PAGE_TYPE;
  backLink?: string;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  locationId,
  pageType,
  backLink,
  indexQuery,
  initialStateAdditionalParams = {},
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isTrial = pageType === PAGE_TYPE.TRIALS;
  const urlEntityType = useCallback(
    params => (isTrial ? `/trial-${params}` : `/${params}`),
    [pageType],
  );

  const [versionId, setVersionId] = useState();
  const [showPreview] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: PreviewModal,
    props: { versionId, isTimeplate: false },
  });

  const onShowPreview = id => () => {
    if (id) {
      setVersionId(id);
      showPreview();
    }
  };

  const timeplateTooltip = isTrial
    ? t(translations.actions.names.trialTimeplates)
    : t(translations.actions.names.timeplates);

  const signatureUserTooltip = isTrial
    ? t(translations.actions.names.trialUsers)
    : t(translations.actions.names.signaturesUser);

  const columns = [
    {
      Header: t(translations.table.columns.signatureName),
      accessor: 'name',
      customProps: row =>
        urlEntityType(
          locationId
            ? `timeplates/${row.location.companyId}/${row.locationId}/${row.id}`
            : `timeplates/${row.location.companyId}/${row.id}`,
        ),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      customProps: row =>
        urlEntityType(
          locationId
            ? `signature-users/${row.location.companyId}/${row.locationId}/${row.id}`
            : `signature-users/${row.location.companyId}/${row.id}`,
        ),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.timeplates),
      id: 'timeplatesCount',
      accessor: 'timeplatesCount',
      customProps: row =>
        urlEntityType(
          locationId
            ? `timeplates/${row.location.companyId}/${row.locationId}/${row.id}`
            : `timeplates/${row.location.companyId}/${row.id}`,
        ),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.activeTimeplates),
      accessor: item => item,
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original.timeplates?.find(({ isActive }) => isActive)?.name || '',
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 375,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({ id, timeplates, locationId }) => {
        return { id, timeplates, locationId };
      },
      Cell: ({
        cell: {
          value: { id, timeplates, locationId },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div>
            <Link
              className={classes.actionItem}
              href={urlEntityType(`signatures/${id}/edit`)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.preview)}
              >
                <SvgIcon
                  component={eyeLightIcon}
                  viewBox="0 0 22 17"
                  onClick={onShowPreview(
                    timeplates?.find(({ isActive }) => !!isActive)
                      ?.activeVersionId,
                  )}
                />
              </Tooltip>
            </span>
            <Link
              className={classes.actionItem}
              href={urlEntityType(
                `signature-users/${companyId}/${locationId}/${id}`,
              )}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={signatureUserTooltip}
              >
                <SvgIcon component={userLightIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={urlEntityType(
                `timeplates/${companyId}/${locationId}/${id}`,
              )}
            >
              <Tooltip placement="top" arrow={true} title={timeplateTooltip}>
                <SvgIcon component={clockLightIcon} viewBox="0 0 18 19" />
              </Tooltip>
            </Link>

            <span className={classes.disabled}>
              <SvgIcon component={addCollectionIcon} viewBox="0 0 512 410" />
            </span>
            <span className={classes.disabled}>
              <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
            </span>
            <span className={classes.disabled}>
              <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
            </span>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const pageName = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.PAID:
        return t(translations.names.paid.signatures);
      default:
        return t(translations.names.trials.signatures);
    }
  }, [pageType, t]);

  const initialState = useMemo(
    () => ({
      globalFilter: {
        ...(locationId ? { locationId } : { 'location.companyId': companyId }),
      },
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
      additionalParams: initialStateAdditionalParams,
    }),
    [companyId, locationId],
  );

  return (
    <Paper
      header={<Typography variant="h5">{pageName()}</Typography>}
      backLink={backLink}
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <SignaturesSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
