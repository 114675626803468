import { createStyles, Theme } from '@material-ui/core/styles';

import { colorPrimary, colorWhite } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    searchField: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 300,
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: '10px',
      },
    },
    searchInputWrapper: {
      paddingLeft: 10,
      marginLeft: -10,

      '&, &:hover': {
        '& $searchNotchedOutline': {
          borderColor: 'transparent',
        },
      },
    },
    searchInputWrapperFocused: {
      backgroundColor: colorWhite,

      '&, &:hover': {
        '& $searchNotchedOutline': {
          borderColor: colorPrimary,
        },
      },
    },
    searchInput: {
      fontSize: 14,
      padding: '16px 10px',

      '&::-webkit-input-placeholder': {
        color: colorPrimary,
        opacity: 1,
      },
      '&::-moz-placeholder': {
        color: colorPrimary,
        opacity: 1,
      },
    },
    searchNotchedOutline: {},
    searchIconWrapper: {
      marginRight: 5,

      '& svg': {
        color: colorPrimary,
        fontSize: 20,
      },
    },
  });
