import {
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { PaginatedResponse } from 'app/interfaces';

import { SignatureUserEntity as UserEntity } from '../interfaces';

const endpoint = '/users';
const onboardingEndpoint = '/account-holder-onboarding';

export const userIndex = queryData => {
  return apiClient.get(endpoint, {
    params: queryData,
  });
};

export const firstStep = data => {
  return apiClient.post(`${onboardingEndpoint}/first-step`, data);
};

export const useFirstStep = () =>
  useMutation(['useFirstStep'], data => firstStep(data));

export const secondStep = data => {
  return apiClient.post(`${onboardingEndpoint}/second-step`, data);
};

export const useSecondStep = () =>
  useMutation(['useSecondStep'], data => secondStep(data));

export const thirdStep = data => {
  return apiClient.post(`${onboardingEndpoint}/third-step`, data);
};

export const useTrirdStep = () =>
  useMutation(['useThirdStep'], data => thirdStep(data));

export const fourthStep = data => {
  return apiClient.post(`${onboardingEndpoint}/fourth-step`, data);
};

export const useFourthStep = () =>
  useMutation(['useFourthStep'], data => fourthStep(data));

export const fifthStep = data => {
  return apiClient.post(`${onboardingEndpoint}/fifth-step`, data);
};

export const useFifthStep = () =>
  useMutation(['useFifthStep'], data => fifthStep(data));

export const sixthStep = params => {
  return apiClient.get<any, any>(`${onboardingEndpoint}/sixth-step`, {
    params,
  });
};

export const useSixthStep = data =>
  useQuery(['useSixthStep'], () => sixthStep(data));

export const seventhStep = params => {
  return apiClient.get<any, any>(`${onboardingEndpoint}/seventh-step`, {
    params,
  });
};

export const useSeventhStep = (data, enabled) =>
  useQuery(['useSeventhStep'], () => seventhStep(data), { enabled });

export const eighthStep = data => {
  return apiClient.patch(`${onboardingEndpoint}/eighth-step`, data);
};

export const useEighthStep = () =>
  useMutation(['useEighthStep'], data => eighthStep(data));

export const useUserIndex: <T = PaginatedResponse<UserEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexUsers', any], any>(
    ['indexUsers', queryData],
    () => userIndex(queryData),
    options,
  );

export const useUserFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<UserEntity>, any>,
) => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch: refetchUsers,
  } = useUserIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetchUsers };
};
